/** @jsx jsx */
import { jsx } from 'theme-ui'

const DescriptionList = props => {
  return (
    <dl
      {...props}
      sx={{
        dt: { variant: 'text.smallHeading', fontWeight: '700' },
        dd: {
          mt: 2,
          mb: 4,
          lineHeight: 1.25,
          fontWeight: 300,
          fontSize: [2, 3],
        },
      }}
    >
      {props.children}
    </dl>
  )
}

export default DescriptionList
